<template>
  <div class="softwareComponentCommandEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Software Component Command
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          ref="name"
          v-model="softwareComponentCommand.name"
          v-focus
          required
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>SoftwareComponent Sub Type</label>
        <input
          ref="softwareComponentSubType"
          v-model="softwareComponentCommand.softwareComponentSubType"
          required
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Role</label>
        <Multiselect
          id="role"
          v-model="commandRoles"
          :multiple="true"
          :searchable="true"
          :options="roles"
          :hide-selected="true"
          :close-on-select="true"
          class="mb-3"
        />
        <hr class="m-0 mb-3">
        <label>Command</label>
        <textarea
          ref="command"
          v-model="softwareComponentCommand.command"
          required
          :class="['form-control', { 'is-invalid': $validator.errors.has('Command') }]"
          rows="3"
        />
        <hr class="m-0 mb-3">
        <label>Command Type</label>
        <input
          ref="commandType"
          v-model="softwareComponentCommand.commandType"
          required
          :class="['form-control', { 'is-invalid': $validator.errors.has('CommandType') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Options</label>
        <textarea
          ref="commandOptions"
          v-model="softwareComponentCommand.options"
          required
          :class="['form-control', { 'is-invalid': $validator.errors.has('Options') }]"
          rows="3"
        />
        <hr class="m-0 mb-3">
        <label>Description</label>
        <textarea
          ref="commandDescription"
          v-model="softwareComponentCommand.description"
          required
          :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
          rows="4"
        />
        <hr class="m-0 mb-3">
        <div class="row mt-3">
          <div class="col-12">
            <div class="cell">
              <h5>{{ 'Command Placeholders' }}</h5>
              <div class="row">
                <span
                  class="btn btn-secondary"
                  @click="addPlaceholder('{domain_name}')"
                >
                  {{ 'Domain Name' }}
                </span>
                <span
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{parameter}')"
                >
                  {{ 'Parameter' }}
                </span>
                <span
                  class="btn btn-secondary ml-3"
                  @click="addPlaceholder('{installation_id}')"
                >
                  {{ 'Installation ID' }}
                </span>
              </div>
              <div class="row mt-2">
                <span
                  class="btn btn-secondary"
                  @click="addPlaceholder('{manual_configuration:parameter}')"
                >
                  {{ 'Manual Configuration Parameter' }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn btn-primary float-right"
          :disabled="!commandRoles.length"
          @click.prevent="editSoftwareComponentCommand()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </div>
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoftwareComponentCommandEdit",
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [
    errorMixin
  ],
  props: {
    softwareComponentCommand: {
      type: Object,
      required: true
    }
  },
   data () {
    return {
      roles: ['admin', 'firstlevel', 'secondlevel', 'customer'],
      commandRoles: []
    }
  },
  created() {
    this.commandRoles = this.softwareComponentCommand.role.split(";")
  },
  methods: {
    addPlaceholder (val) {
      if (!this.softwareComponentCommand.command) {
          this.softwareComponentCommand.command = "";
        }
        var commandArea = this.$refs.command;
        var cursorPosition = commandArea.selectionStart;
        var command = this.softwareComponentCommand.command;
        if(val == '{parameter}') {
          var count = (command.match(/{parameter_/g) || []).length;
          val = '{parameter_' + (count+1) +'}'
        }
        this.softwareComponentCommand.command = command.substring(0, cursorPosition) + val + command.substring(cursorPosition);
        commandArea.focus();
    },
    async editSoftwareComponentCommand () {
      let postObject = {
        'id': this.softwareComponentCommand.id,
        'name': this.softwareComponentCommand.name,
        'command': this.softwareComponentCommand.command,
        'commandType': this.softwareComponentCommand.commandType,
        'options': this.softwareComponentCommand.options,
        'componentTypeId': this.softwareComponentCommand.componentTypeId,
        'role': this.commandRoles.join(";"),
        'description': this.softwareComponentCommand.description,
        'softwareComponentSubType': this.softwareComponentCommand.softwareComponentSubType
      }
      
      await this.axios.put('/SoftwareComponent/UpdateSoftwareComponentCommand', postObject)
        .then(() => {
          this.$snotify.success(this.$t('softwareComponent.commandUpdatedSuccessfully'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.softwareComponentCommandEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>